import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../../../src/app/providers/common-data.service';
import { Helper_Class } from '../../../../src/app/helper_class';
import { ipaddress } from '../../ipaddress';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { response } from 'express';
import { error } from 'console';
import { Message_data } from '../../../assets/js/Message_data';
import { Date_Formate, Time_Formate } from 'src/assets/js/common';

@Component({
  selector: 'app-pharma-stock-status',
  templateUrl: './pharma-stock-status.component.html',
  styleUrls: ['./pharma-stock-status.component.css'],
  animations: [
    trigger('slideToggle', [
      state('void', style({ height: '0px', opacity: 0})),
      state('*', style({ height: '*', opacity: 1})),
      transition('void <=> *', [
        animate('400ms ease-in-out')
      ])
    ])
  ]
})
export class PharmaStockStatusComponent implements OnInit {
[x: string]: any;
  @ViewChild('input', { static: false }) input: ElementRef;
  public locationList: any = [];
  public LoadLocationArray: any = [];
  public pharmacyFacID: any;
  public location: any;
  public locIP = null;
  public stockData: any = [];
  public pharmacistID;
  public userInfo;
  public pharmacyID;
  dtOptions: DataTables.Settings = {};
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public prevPageCount: boolean;
  public nextPageCount: boolean;
  public noOfPages: any;
  public currentPage: any;
  public pagelist: any;
  public sno;
  public usertype: string;
  searchby: any;
  public typeflag: string;
  public patientName: string;
  public searchtext: string;
  public patientid;
  public pageSize;
  public pageNavigate: boolean;
  public clientCount;
  public dept_id;
  public store_id;
  public dept_desc;
  public store_desc;
  public fin_year;
  currentOpenedIndex: number | null = null;
  public fromDate:string;
  public toDate:string;
  public currentDate:string;
  public rowtable:any=[];
  public departname;
  public storename;
  public hs_id;
  public exceldata:any=[];

  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.pageSize = 50;
    this.currentPage = 1;
    this.pageNavigate = true
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      order: [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search product name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.userInfo = Helper_Class.getInfo();
    this.pharmacistID = this.userInfo.user_id;
    this.usertype = this.userInfo.user_type;
    this.dept_id=this.userInfo.hospitals[0].department_id;
    this.store_id=this.userInfo.hospitals[0].store_id;
    this.dept_desc=this.userInfo.hospitals[0].dept_desc;
    this.store_desc=this.userInfo.hospitals[0].store_desc;
    this.fin_year=this.userInfo.fin_year;
    this.pagelist = "25"
    this.getCurrentDate();
    if(this.userInfo.user_type == "pharmacy"){
      this.pharmacyID= this.userInfo.hospitals[0].hptl_clinic_id
      this.hs_id = Helper_Class.getInfo().hospitals[0].hosp_id
    }else if(this.userInfo.user_type == "Admin"){
      this.pharmacyID = this.userInfo.hospitals[0].pharma_id;
      this.hs_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    } 
    else{
      this.pharmacyID = this.userInfo.hospitals[0].pharma_id;
      this.hs_id = Helper_Class.getInfo().hospitals[0].hosp_id
    }
    if(this.userInfo.user_type != "Admin") {
      if (Helper_Class.getmodulelist() != undefined) {
        for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
          if (Helper_Class.getmodulelist()[i].module_id == "122") {
            if (Helper_Class.getmodulelist()[i].edit == "1") {
              this.editbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].create == "1") {
              this.newbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].delete == "1") {
              this.deletebutton = true;
            }
            if (Helper_Class.getmodulelist()[i].print == "1") {
              this.printbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].view == "1") {
              this.viewbutton = true;
            }
  
  
          }else{
            this.editbutton = true;
            this.viewbutton = true;
            this.printbutton = true;
            this.newbutton = true;
            this.deletebutton = true; 
          }
        }
      }
    } else {
      this.editbutton = true;
      this.viewbutton = true;
      this.printbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
    }
    
    
    this.pharmaLocation();
    this.getdepart()
  }
  pharmaLocation() {
    var send_data;
    if (Helper_Class.getIsAdmin() != undefined) {
      send_data = JSON.stringify({
        pharmacy_id: this.pharmacyID,
      });
    } else {
      send_data = JSON.stringify({
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.pharmacistID
      });
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/pharlocbyid/", send_data, { headers: headers }).subscribe(
      data => {
        var obj = data.json();
        if (data.json().pharma_locations != null) {
          this.locationList = data.json().pharma_locations;
          for (var i = 0; i < this.locationList.length; i++) {
            this.LoadLocationArray.push({
              location_id: this.locationList[i].pharma_facility_id + " - " + this.locationList[i].location,
              pharma_facility_id: this.locationList[i].pharma_facility_id,
            });
          }
          if (this.LoadLocationArray.length != 0) {
            this.location = this.LoadLocationArray[0].location_id;
          }
        }
      },
      error => { });
  }
  getCurrentDate() {

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("current_date"+JSON.stringify(obj))
          this.currentDate = obj.current_date;
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          var currenttime1 = obj.current_time.split(":");
          this.currenttime = currenttime1[0]
          this.presentdate = obj.current_date;
          console.log('CURRENT_DATE', this.presentdate);
          this.navpagelist()
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }



  focusInput() {
    this.patientName = "";
    document.getElementById("filterInput").focus();
    this.input.nativeElement.focus();
  }
  searchType(data) {
    this.searchby = data;
    if (data == "name") {
      this.typeflag = "name";
      this.patientName = "";
      this.searchtext = "search by name";
      // this.getPatientListByName(this.patientName);

    } else if (data == "patientid") {
      this.typeflag = "patientid";
      this.patientName = "";
      this.searchtext = "search by patient id";
      this.patientid = true;
      // this.getPatientListByName(this.patientName);

    } else {
      this.typeflag = "mobile";
      this.patientName = "";
      this.searchtext = "search by mobile";
      // this.getPatientListByName(this.patientName);
    }
  }

  changeDate(){
    if(this.patientName != ""){
      this.getdatausingname(this.patientName)
    }else{
      this.changePageList()
    }
  }

  changePageList() {
  
    var send_data;
    if (this.usertype == "Admin") {
      send_data = {
      pharmacy_id: this.pharmacyID,
        limit: this.pagelist,
        sno: this.sno,//currentLimit,
        dept_id:this.departname,
        store_id:this.storename,
        from_date:this.fromDate,
        to_date:this.toDate
      }
    } else {
      send_data = {
        pharmacy_id: this.pharmacyID,
        limit: this.pagelist,
        sno: this.sno,
        dept_id:this.dept_id,
        store_id:this.store_id,
        from_date:this.fromDate,
        to_date:this.toDate
      }
    }
    console.log(" SEND DATA = " + JSON.stringify(send_data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gpsbl", send_data,
      { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          console.log("stock data 2 = " + JSON.stringify(obj))
          if (obj.key != 0) {
            if (obj.stock_onhand != null) {
              this.stockData = [];
              this.pageNavigate = obj.prod_count > 50 ? false : true;
              this.clientCount = obj.prod_count
              for (var i = 0; i < obj.stock_onhand.length; i++) {
                if (obj.stock_onhand[i].exp_date != undefined) {
                  var expdate = obj.stock_onhand[i].exp_date.split('-');
                  obj.stock_onhand[i].exp_date = expdate[2] + "-" + expdate[1] + "-" + expdate[0]
                }
                var count = this.sno + (i)
                console.log("count = " + count)
                this.stockData.push({
                  sn: count,
                  product_id:obj.stock_onhand[i].product_id,
                  short_name: obj.stock_onhand[i].short_name || '',
                  name: obj.stock_onhand[i].name,
                  supplier: obj.stock_onhand[i].supplier,
                  batch_no: obj.stock_onhand[i].batch_no,
                  rack: obj.stock_onhand[i].rack_id,
                  bin: obj.stock_onhand[i].bin_id,
                  exp_date: obj.stock_onhand[i].exp_date,
                  min_level: obj.stock_onhand[i].min_level,
                  max_level: obj.stock_onhand[i].max_level,
                  danger_level: obj.stock_onhand[i].danger_level,
                  qty_onhand: obj.stock_onhand[i].qty_onhand,
                  product_list:obj.stock_onhand[i].product_list ||[].map(element => {
                    element.received_date = element.received_date !== undefined && element.received_date !== '' 
                      ? Date_Formate(element.received_date) : '';
                    element.received_time = element.received_time !== undefined && element.received_time !== '' 
                      ? Time_Formate(element.received_time) : '';
                    return element;
                  }),
                  showDetails: false
                })
              }
              this.stockData.forEach(data => {
                  data.product_list.forEach(element => {
                    if (element.received_date) {
                      element.received_date = Date_Formate(element.received_date);
                    }
                    if (element.received_time) {
                      element.received_time = Time_Formate(element.received_time);
                    }
                  });
              });
              console.log("stock data = " + JSON.stringify(this.stockData))
            }

            if(obj.product_details != null && obj.product_details != undefined){
              for(var j = 0; j < obj.product_details.length; j++){
                obj.product_details[j].received_date = obj.product_details[j].received_date != undefined && obj.product_details[j].received_date != '' ? Date_Formate(obj.product_details[j].received_date):'' ;
                obj.product_details[j].received_time = obj.product_details[j].received_time != undefined && obj.product_details[j].received_time != '' ? Time_Formate(obj.product_details[j].received_time):'' ;
              this.exceldata.push({
                product_name:obj.product_details[j].product_name,
                batch_no:obj.product_details[j].batch_no,
                type:obj.product_details[j].type,
                trans_no:obj.product_details[j].trans_no,
                name:obj.product_details[j].name,
                received_date:obj.product_details[j].received_date +" "+obj.product_details[j].received_time,
                mrp:obj.product_details[j].mrp,
                quantity:obj.product_details[j].quantity
              })
            }
            
            }
          }
        },
        error => { });

  }

  getdatausingname(value) {
    this.close_toggle()
    if (value.length > 3) {
      var send_data
      if (this.usertype == "Admin") {
        send_data = {
          pharmacy_id: this.pharmacyID,
          prod_name: value,
          dept_id:this.departname,
          store_id:this.storename,
          from_date:this.fromDate,
          to_date:this.toDate
        }
      } else {
        send_data = {
          pharmacy_id: this.pharmacyID,
          prod_name: value,
          dept_id:this.dept_id,

          store_id:this.store_id,
          from_date:this.fromDate,
          to_date:this.toDate
        }
      }
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inv/gpsbl", send_data,
        { headers: headers }).subscribe(
          data => {
            var obj = data.json();
            console.log("stock data 3 = " + JSON.stringify(obj))
            if (obj.key != 0) {
              if (obj.stock_onhand != null) {
                this.stockData = [];
                for (var i = 0; i < obj.stock_onhand.length; i++) {
                  if (obj.stock_onhand[i].exp_date != undefined) {
                    var expdate = obj.stock_onhand[i].exp_date.split('-');
                    obj.stock_onhand[i].exp_date = expdate[2] + "-" + expdate[1] + "-" + expdate[0]
                  }
                  var count = this.sno + (i)
                  this.stockData.push({
                    sn: count,
                    product_id:obj.stock_onhand[i].product_id,
                    short_name: obj.stock_onhand[i].short_name || '',
                    name: obj.stock_onhand[i].name,
                    supplier: obj.stock_onhand[i].supplier,
                    batch_no: obj.stock_onhand[i].batch_no,
                    rack: obj.stock_onhand[i].rack_id,
                    bin: obj.stock_onhand[i].bin_id,
                    exp_date: obj.stock_onhand[i].exp_date,
                    min_level: obj.stock_onhand[i].min_level,
                    max_level: obj.stock_onhand[i].max_level,
                    danger_level: obj.stock_onhand[i].danger_level,
                    qty_onhand: obj.stock_onhand[i].qty_onhand,
                    product_list:obj.stock_onhand[i].product_list ||[].map(element => {
                      element.received_date = element.received_date !== undefined && element.received_date !== '' 
                        ? Date_Formate(element.received_date) 
                        : '';
                      element.received_time = element.received_time !== undefined && element.received_time !== '' 
                        ? Time_Formate(element.received_time) 
                        : '';
                      return element;
                    }),
                    showDetails: false
                  })
                }

                this.stockData.forEach(data => {
                  data.product_list.forEach(element => {
                    if (element.received_date) {
                      element.received_date = Date_Formate(element.received_date);
                    }
                    if (element.received_time) {
                      element.received_time = Time_Formate(element.received_time);
                    }
                  });
              });
                console.log("stock data = " + JSON.stringify(this.stockData))
              }
              if(obj.product_details != null && obj.product_details != undefined){
                for(var j = 0; j < obj.product_details.length; j++){
                  obj.product_details[j].received_date = obj.product_details[j].received_date != undefined && obj.product_details[j].received_date != '' ? Date_Formate(obj.product_details[j].received_date):'' ;
                  obj.product_details[j].received_time = obj.product_details[j].received_time != undefined && obj.product_details[j].received_time != '' ? Time_Formate(obj.product_details[j].received_time):'' ;
                  
                  this.exceldata.push({
                    product_name:obj.product_details[j].product_name,
                    batch_no:obj.product_details[j].batch_no,
                    type:obj.product_details[j].type,
                    trans_no:obj.product_details[j].trans_no,
                    name:obj.product_details[j].name,
                    received_date:obj.product_details[j].received_date +" "+obj.product_details[j].received_time,
                    mrp:obj.product_details[j].mrp,
                    quantity:obj.product_details[j].quantity
                })
              }

              }
            }
          },
          error => { });

    } else if (value.length == 0) {
   
      this.changePageList()
    }

  }
  pageLimitnext() {
    if (this.currentPage == this.noOfPages){
      this.nextPageCount = true;}
    else{
    this.currentPage = parseInt(this.currentPage);
    
    this.pageSize = this.pagelist;
    this.prevPageCount = false;
    this.sno = (parseInt(this.currentPage) * parseInt(this.pagelist)) + 1;
   
    this.changePageList();
    if (this.currentPage == this.noOfPages)
      this.nextPageCount = true;

    
    this.currentPage = this.currentPage + 1;
    }
  }

  pageLimitPrevious() {
    if (this.currentPage == "1"){
      this.prevPageCount = true;}
    else{
      this.currentPage = this.currentPage - 1;
   
    this.pageSize = this.pagelist;
    if (this.currentPage != 1)
      this.sno = ((parseInt(this.currentPage) - 1) * parseInt(this.pagelist)) + 1;
    else if (this.currentPage == 1)
      this.sno = 1;


    this.changePageList();
    this.nextPageCount = false;
    }
  }

  navpagelist() {
    this.currentPage = 1;
    this.sno = 1;
    this.pageSize = this.pagelist;

    this.changePageList();
    console.log(this.stockData.length);
    if (this.pagelist == "25") {
      if (this.clientCount > 25) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      }
    } else if (this.pagelist == "50") {
      if (this.clientCount > 50) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }
    } else if (this.pagelist == "100") {
      if (this.clientCount > 100) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }
    }
  }
  numberOfPages() {
    this.noOfPages = Math.ceil(this.clientCount / this.pagelist);
    return Math.ceil(this.clientCount / this.pagelist);
   
    console.log("client count " + this.noOfPages)

   
  }

  close_toggle(){
    this.currentOpenedIndex = null;
  }

  toggleInputs(index: number) {
    if (this.currentOpenedIndex === index) {
      this.currentOpenedIndex = null; // Close the currently opened row
    } else {
      this.currentOpenedIndex = index; // Open the new row and close the previous one
    }
 
  }

  rowdata(data){
    if(this.usertype == "Admin"){
      var send_data={
        pharma_id:this.pharmacyID,
        dept_id:this.departname,
        store_id:this.storename,
        product_id:data.product_id,
        batch_no:data.batch_no,
        from_date:this.fromDate,
        to_date:this.toDate
      };
    }else{
    var send_data={
      pharma_id:this.pharmacyID,
      dept_id:this.dept_id,
      store_id:this.store_id,
      product_id:data.product_id,
      batch_no:data.batch_no,
      from_date:this.fromDate,
      to_date:this.toDate
    };
  }
    console.log("send_data"+JSON.stringify(send_data))
    var headers=new Headers();
    headers.append('Content-Type','application/json');
    this.http.post(ipaddress.getIp.toString()+'inv/gsld',send_data,{headers:headers}).subscribe(response =>{
      var obj=JSON.parse(response['_body'])
      console.log("response = "+JSON.stringify(obj))
      if(obj.product_list != undefined){
        if(obj.product_list.length != 0){
          this.rowtable=[];
          obj.product_list.forEach(element => {
            element.received_date = element.received_date != undefined && element.received_date != '' ? Date_Formate(element.received_date):'' ;
            element.received_time = element.received_time != undefined && element.received_time != '' ? Time_Formate(element.received_time):'' ;
            element.mrp = element.mrp != undefined && element.mrp != '' ? element.mrp:'0.00' ;

            this.rowtable.push({
                  received_date	:	element.received_date +" "+ element.received_time,
                  rece_date:element.received_date,
                  received_time	:	element.received_time,
                  batch_no	:	element.batch_no,
                  name	:	element.name,
                  type	:	element.type,
                  trans_no	:	element.trans_no,
                  mrp	:	element.mrp,
                  quantity	:	element.quantity
                })
          });
        }else{
          this.rowtable=[];
        }
      }else{
        this.rowtable=[];
      }
  },error=>{
  }
)
  }

  getdepart() {
    console.log("pharmacy Id =" + 
      JSON.stringify({ 
        hptl_clinic_id: this.pharmacyID, 
        type: this.user_type }))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "gen/gdbh", 
      JSON.stringify({ hptl_clinic_id: this.pharmacyID, type: this.usertype }), { headers: headers }).subscribe(
      response => {
        var obj = response.json()
        console.log(" DEPARTMENT_DATA" + JSON.stringify(obj))
        this.departmentarray = obj.departments;
      }
    )
  }

  pobase_department(value) {
    
    this.getstorename(this.departname)
  }

  getstorename(value) {

    var senddata = {
      hptl_clinic_id: this.hs_id,
      department_id: value
    }

    console.log("senddata = " + JSON.stringify(senddata))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "gen/gsdbh", 
    JSON.stringify({ hptl_clinic_id: this.hs_id, department_id: value }), 
    { headers: headers }).subscribe(
    response => {
        var obj = response.json()
        console.log(" store DATA" + JSON.stringify(obj));
        this.storearray = obj.stores;
        if (this.storearray.length == 1) {
          this.storename = this.storearray[0].store_names_id

          this.navpagelist()
        }
        // this.departmentarray=obj.departments;
      },
      error => {
        console.log("error on retriving the store name")
      }
    )
  }

  exportToExcel() {
    var excel_data: any = [];
    var header = ["Product id","Medicine","Expiery date","Rack","Bin","Max level","Min level","Danger","Avaliable qty"]
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Stock status report');
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })
    for (let stock of this.stockData) {
          excel_data.push({
            product_id:stock.product_id,
            name: stock.short_name+" "+stock.name,
            exp_date: stock.exp_date,
            rack:stock.rack,
            bin:stock.bin,
            min_level:stock.min_level,
            max_level:stock.max_level,
            danger_level:stock.danger_level,
            qty_onhand: stock.qty_onhand,
          })
    }

    for (let x1 of excel_data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      let chilrow = worksheet.addRow(temp)
      let salesamt = chilrow.getCell(7);
      salesamt.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
    }
    var header_supplier = [ "Product name","batch_no","Transation Type","Transation no","Department Name / Patient","Date", "MRP", "Quantity"]
    // let workbook_supplier = new Workbook();
    // let worksheet_supplier = workbook_supplier.addWorksheet('Stock status report');
    // worksheet_supplier.getColumn(1).width = 40;
    // worksheet_supplier.getColumn(2).width = 20;
    // worksheet_supplier.getColumn(3).width = 15;
    // worksheet_supplier.getColumn(4).width = 20;
    // worksheet_supplier.getColumn(5).width = 20;
    // worksheet_supplier.getColumn(6).width = 15;
    // worksheet_supplier.getColumn(7).width = 15;
    var emptyRow = worksheet.addRow(" ")
    var emptyRowsec = worksheet.addRow(" ")
    let headerRow_supplier = worksheet.addRow(header_supplier);
    emptyRow.eachCell(() => { })
    emptyRow.eachCell(() => { })
    emptyRowsec.eachCell(() => { })
    headerRow_supplier.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })

    for (let x3 of this.exceldata) {
      let x4 = Object.keys(x3);
      let temp = []
      for (let y of x4) {
        temp.push(x3[y])
      }
      let chilrow = worksheet.addRow(temp)
      let salesamt = chilrow.getCell(7);
      salesamt.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "stock_status_report" + '-' + new Date().valueOf() + '.xlsx');
    });
  }

}
